body,
html {
	height: 100%;
	margin: 0;
	font-family: Arial, sans-serif;
	background-color: rgb(248, 207, 167);
}

.main-container {
	width: 100%;
	height: 100%;
	padding: 20px;
	box-sizing: border-box;
}

.game-title {
	color: white;
	text-align: left;
	margin: 0;
}

button:hover,
a:hover,
#ShareImage {
	opacity: 1.0;
}

#AnswerInput {
	width: calc(100% - 40px);
	padding: 10px;
	border: none;
	border-radius: 5px;
	font-size: 25pt;
	font-family: Arial, sans-serif;
	text-align: right;
	font-weight: 600;
	outline: none;
	resize: none;
	color: rgb(19, 5, 0);
	background: transparent;
}

@keyframes wiggle {
	0% {
		transform: rotate(0deg);
	}

	10% {
		transform: rotate(2deg);
		color: red;
	}

	20% {
		transform: rotate(0deg);
		color: red;
	}

	30% {
		transform: rotate(-2deg);
		color: red;
	}

	40% {
		transform: rotate(0deg);
		color: red;
	}
}

.wiggle1 {
	display: inline-block;
	animation: wiggle 0.7s;
}

.wiggle2 {
	display: inline-block;
	animation: wiggle 0.7s;
}

.wiggle3 {
	display: inline-block;
	animation: wiggle 0.7s;
}

.loader {
	border: 8px solid rgb(248, 207, 167);
	/* Light grey */
	border-top: 8px solid rgb(255, 249, 243);
	/* Blue */
	border-radius: 50%;
	width: 60px;
	height: 60px;
	margin: auto;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}